import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ReactPlayer from "react-player/youtube"

import VideoOnePoster from "../../images/video-posters/fr/catherine-brandicourt-quote-card.jpg"
import VideoTwoPoster from "../../images/video-posters/fr/anne-chagnas.jpg"
import VideoThreePoster from "../../images/video-posters/fr/nada-sabila-quote-card.jpg"
// import VideoFourPoster from "../../images/video-posters/mx/tere_lerma.jpg"
// import VideoFivePoster from "../../images/video-posters/mx/valeria_mozo.jpg"
// import VideoSixPoster from "../../images/video-posters/mx/linda-rennings.jpg"
// import VideoSevenPoster from "../../images/video-posters/mx/monica-glass.jpg"
// import VideoEightPoster from "../../images/video-posters/mx/ameesha-durham.jpg"
// import VideoNinePoster from "../../images/video-posters/mx/tumi-masekela.jpg"

// import VideoTenPoster from "../../images/video-posters/mx/pilar-gonzalez.jpg"
// import VideoElevenPoster from "../../images/video-posters/mx/yelile-habib.jpg"

const HearOtherWomenVoicesRight = () => {
  const [videoOne, setVideoOne] = useState(false)
  const [videoTwo, setVideoTwo] = useState(false)
  const [videoThree, setVideoThree] = useState(false)
  // const [videoFour, setVideoFour] = useState(false)
  // const [videoFive, setVideoFive] = useState(false)
  // const [videoSix, setVideoSix] = useState(false)
  // const [videoSeven, setVideoSeven] = useState(false)
  // const [videoEight, setVideoEight] = useState(false)
  // const [videoNine, setVideoNine] = useState(false)
  // const [videoTen, setVideoTen] = useState(false)
  // const [videoEleven, setVideoEleven] = useState(false)

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: () => {
      setVideoOne(false)
      setVideoTwo(false)
      setVideoThree(false)
      // setVideoFour(false)
      // setVideoFive(false)
      // setVideoSix(false)
      // setVideoSeven(false)
      // setVideoEight(false)
      // setVideoNine(false)
      // setVideoTen(false)
      // setVideoEleven(false)
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <>
      <div
        className="content carousel-hear-other-women-voices"
        // onTouchEnd={props.setSection("hear-other-women-voices")}
      >
        <Slider {...settings}>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoOne && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoOne(true)}
                >
                  <img className="img-fluid" src={VideoOnePoster} alt="" />
                </figure>
              )}
              {videoOne && (
                <ReactPlayer
                  url="https://youtu.be/8Zvm-rgSYVE"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoTwo && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoTwo(true)}
                >
                  <img className="img-fluid" src={VideoTwoPoster} alt="" />
                </figure>
              )}
              {videoTwo && (
                <ReactPlayer
                  url="https://youtu.be/J1fQJRYi0Kw"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoThree && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoThree(true)}
                >
                  <img className="img-fluid" src={VideoThreePoster} alt="" />
                </figure>
              )}
              {videoThree && (
                <ReactPlayer
                  url="https://youtu.be/7EBFOv43XYM"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          {/* <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoFour && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoFour(true)}
                >
                  <img className="img-fluid" src={VideoFourPoster} alt="" />
                </figure>
              )}
              {videoFour && (
                <ReactPlayer
                  url="https://youtu.be/YvBLOYu9TBg"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoFive && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoFive(true)}
                >
                  <img className="img-fluid" src={VideoFivePoster} alt="" />
                </figure>
              )}
              {videoFive && (
                <ReactPlayer
                  url="https://youtu.be/F4MDUemC3WA"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div> */}
          {/* <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoSix && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoSix(true)}
                >
                  <img className="img-fluid" src={VideoSixPoster} alt="" />
                </figure>
              )}
              {videoSix && (
                <ReactPlayer
                  url="https://youtu.be/DbgdUpXZKlo"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoSeven && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoSeven(true)}
                >
                  <img className="img-fluid" src={VideoSevenPoster} alt="" />
                </figure>
              )}
              {videoSeven && (
                <ReactPlayer
                  url="https://youtu.be/pGolDZXLQ3Q"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoEight && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoEight(true)}
                >
                  <img className="img-fluid" src={VideoEightPoster} alt="" />
                </figure>
              )}
              {videoEight && (
                <ReactPlayer
                  url="https://youtu.be/1M--qT1CjCM"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoNine && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoNine(true)}
                >
                  <img className="img-fluid" src={VideoNinePoster} alt="" />
                </figure>
              )}
              {videoNine && (
                <ReactPlayer
                  url="https://youtu.be/01XcxxdKjUk"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div>
          <div>
            <div className="other-women-voices-slide iframe-hldr ratio ratio-16x9">
              {!videoEleven && (
                <figure
                  className="mb-0 video-poster"
                  onClick={() => setVideoEleven(true)}
                >
                  <img className="img-fluid" src={VideoElevenPoster} alt="" />
                </figure>
              )}
              {videoEleven && (
                <ReactPlayer
                  url="https://youtu.be/aFMiGopiE9U"
                  width="100%"
                  height="100%"
                  controls={true}
                  stopOnUnmount={true}
                />
              )}
            </div>
          </div> */}
        </Slider>
      </div>
    </>
  )
}

export default HearOtherWomenVoicesRight
