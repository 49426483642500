import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ThankyouStickers = () => {
  const imageData = useStaticQuery(graphql`
    query {
      stickerPrioritiseTheClimate: file(
        relativePath: { eq: "stickers/sticker-prioritise-the-climate.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerWipeOutFoodPoverty: file(
        relativePath: { eq: "stickers/sticker-wipe-out-food-poverty.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerHelpCommunities: file(
        relativePath: { eq: "stickers/sticker-help-communities.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerMoreWomenInPower: file(
        relativePath: { eq: "stickers/sticker-answer-more-women-in-power.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerPractical: file(
        relativePath: { eq: "stickers/sticker-practical.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerGenderEquality: file(
        relativePath: { eq: "stickers/sticker-gender-equality.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerSocialHereToBeHeard: file(
        relativePath: { eq: "stickers/sticker-here-to-be-heard.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stickerCommunities: file(
        relativePath: { eq: "stickers/sticker-communities.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <figure className="mb-0 sticker sticker-prioritise-the-climate">
        <Img
          fluid={imageData.stickerPrioritiseTheClimate.childImageSharp.fluid}
          alt=""
        />
      </figure>

      <figure className="mb-0 sticker sticker-wipe-out-food-poverty">
        <Img
          fluid={imageData.stickerWipeOutFoodPoverty.childImageSharp.fluid}
          alt=""
        />
      </figure>

      <figure className="mb-0 sticker sticker-help-communities">
        <Img
          fluid={imageData.stickerHelpCommunities.childImageSharp.fluid}
          alt=""
        />
      </figure>

      <figure className="mb-0 sticker sticker-more-women-in-power">
        <Img
          fluid={imageData.stickerMoreWomenInPower.childImageSharp.fluid}
          alt=""
        />
      </figure>

      <figure className="mb-0 sticker sticker-practical">
        <Img fluid={imageData.stickerPractical.childImageSharp.fluid} alt="" />
      </figure>

      <figure className="mb-0 sticker sticker-gender-equality">
        <Img
          fluid={imageData.stickerGenderEquality.childImageSharp.fluid}
          alt=""
        />
      </figure>

      <figure className="mb-0 sticker sticker-social-here-to-be-heard">
        <Img
          fluid={imageData.stickerSocialHereToBeHeard.childImageSharp.fluid}
          alt=""
        />
      </figure>

      <figure className="mb-0 sticker sticker-communities">
        <Img
          fluid={imageData.stickerCommunities.childImageSharp.fluid}
          alt=""
        />
      </figure>
    </>
  )
}

export default ThankyouStickers
