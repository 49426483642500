import React from "react"

const TheCommunityRightContent = () => (
  <>
    <div className="content content-community right">
      <div className="content-timeline-community">
        <p className="para">
          <span className="bullet"></span>
          Participez par témoignage vocal ou écrit.
        </p>
        <p className="para">
          <span className="bullet"></span>
          Vos réponses et vos idées, ainsi que d’autres du monde entier, seront
          ajoutées à notre etude mondiale.
        </p>
        <p className="para">
          <span className="bullet"></span>
          L’étude viendra alimenter des actions, outils et initiatives conçus
          pour aider davantage de femmes à atteindre leur plein potentiel.
        </p>
      </div>
    </div>
  </>
)

export default TheCommunityRightContent
