import React from "react"

import "./the-community.css"

const TheCommunityLeftContent = props => (
  <>
    <div className="content content-community">
      <h3 className="title title-community">
        Les voix des femmes sont puissantes et doivent être entendues. C'est
        pourquoi nous demandons aux femmes du monde entier de donner de la voix,
        pour que nous puissions construire l'avenir ensemble.
      </h3>

      <div className="d-lg-none">
        <div className="content-timeline-community">
          <p className="para">
            <span className="bullet"></span>
            Participez par témoignage vocal ou écrit.
          </p>
          <p className="para">
            <span className="bullet"></span>
            Vos réponses et vos idées, ainsi que d’autres du monde entier,
            seront ajoutées à notre etude mondiale.
          </p>
          <p className="para">
            <span className="bullet"></span>
            L’étude viendra alimenter des actions, outils et initiatives conçus
            pour aider davantage de femmes à atteindre leur plein potentiel.
          </p>
        </div>
      </div>
      <div
        className="transition-control"
        onTransitionEnd={() => props.setScrollControl(true)}
      >
        Transition Control Element
      </div>
    </div>
  </>
)

export default TheCommunityLeftContent
