import React from "react"

import "./thankyou.css"

const Thankyou = props => {
  return (
    <>
      <div className="content content-thankyou">
        <h1 className="title title-thankyou">
          <span className="one">Merci de </span>
          <span className="two">donner de </span>
          <span className="three">la voix pour </span>
          <span className="four">construire un </span>
          <span className="five">avenir meilleur. </span>
        </h1>
        <div
          className="transition-control"
          onTransitionEnd={() => {
            props.setSection("thankyou social-media")
          }}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default Thankyou
