import React, { useEffect, useRef } from "react"
import Lottie from "lottie-web"
// import Lottie from "react-lottie"

import "./landing.css"

import stickerWomenData from "../../animations/sticker-women.json"
import textFullPotentialData from "../../animations/fr/text-full-potential.json"

const Landing = () => {
  const stickerWomen = useRef(null)
  const textFullPotential = useRef(null)

  useEffect(() => {
    const animStickerWomen = Lottie.loadAnimation({
      container: stickerWomen.current,
      renderer: "svg",
      animationData: stickerWomenData,
      loop: false,
      autoplay: true,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    return () => animStickerWomen.destroy()
  }, [])

  useEffect(() => {
    const animTextFullPotential = Lottie.loadAnimation({
      container: textFullPotential.current,
      renderer: "svg",
      animationData: textFullPotentialData,
      loop: false,
      autoplay: true,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    return () => animTextFullPotential.destroy()
  }, [])

  // const stickerWomenOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: stickerWomenData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // }
  // const textFullPotentialOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: textFullPotentialData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // }

  return (
    <>
      <div className="bg-mars-yellow sticker-women-hldr">
        <div className="position-relative w-100 h-100">
          <div
            className="sticker-women"
            id="sticker-women"
            ref={stickerWomen}
          ></div>

          {/* <div className="sticker-women" id="sticker-women">
          <Lottie options={stickerWomenOptions} />
        </div> */}

          <div
            className="text-full-potential"
            id="text-full-potential"
            ref={textFullPotential}
          >
            {/* <Lottie options={textFullPotentialOptions} /> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Landing
